
import request from '@/utils/http.js'

// 项目信息
export const baseHomeList = data => {
  return request({
    url: '/project/pageList',
    method: 'post',
    data
  })
}

// 项目信息-下载
export const baseHomeListExport = data => {
  return request({
    url: '/project/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 模板下载
export const baseHomeDownloadTemplate = data => {
  return request({
    url: '/project/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 电子围栏
export const getElectricFence = params => {
  return request({
    url: '/electricFence/detail',
    method: 'get',
    params
  })
}

// 电子围栏--编辑
export const getElectricFenceEdit = data => {
  return request({
    url: '/electricFence/edit',
    method: 'post',
    data
  })
}

// 联系人管理
export const contactList = data => {
  return request({
    url: '/contact/list',
    method: 'get',
    params: data
  })
}

// 联系人管理--添加
export const contactListAdd = data => {
  return request({
    url: '/contact/add',
    method: 'post',
    data
  })
}

// 联系人管理--删除
export const contactListDel = data => {
  return request({
    url: '/contact/delete',
    method: 'get',
    params: data
  })
}

// 联系人管理--删除
export const contactListEdit = data => {
  return request({
    url: '/contact/add',
    method: 'get',
    data
  })
}

// 联系人管理--批量保存
export const contactListSave = data => {
  return request({
    url: '/contact/batchAdd',
    method: 'post',
    data
  })
}

// 处置信息
export const baseDisposeList = data => {
  return request({
    url: '/projectLicense/pageList',
    method: 'post',
    data
  })
}

// 处置信息
export const baseDisposeDownloadTemplate = data => {
  return request({
    url: '/projectLicense/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}


//消纳场
export const baseCollectList = data => {
  return request({
    url: '/recycleProject/pageList',
    method: 'post',
    data
  })
}

//消纳场
export const baseCollectListExport = data => {
  return request({
    url: '/recycleProject/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 模板下载
export const baseCollectDownloadTemplate = data => {
  return request({
    url: '/recycleProject/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 车辆
export const baseCarList = data => {
  return request({
    url: '/car/pageList',
    method: 'post',
    data
  })
}

// 车辆
export const baseCarListExport = data => {
  return request({
    url: '/car/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 模板下载
export const baseCarDownloadTemplate = data => {
  return request({
    url: '/car/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}


// 车队
export const baseCarsList = data => {
  return request({
    url: '/fleet/pageList',
    method: 'post',
    data
  })
}

// 车队
export const baseCarsListExport = data => {
  return request({
    url: '/fleet/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 模板下载
export const baseCarsDownloadTemplate = data => {
  return request({
    url: '/fleet/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 准运证
export const baseGrantList = data => {
  return request({
    url: '/permitTranslationLicense/pageList',
    method: 'post',
    data
  })
}

// 准运证下载
export const baseGrantListExport = data => {
  return request({
    url: '/permitTranslationLicense/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 模板下载
export const baseGrantListDownloadTemplate = data => {
  return request({
    url: '/permitTranslationLicense/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 工单
export const centerOrderList = data => {
  return request({
    url: '/recycleOrder/pageList',
    method: 'post',
    data
  })
}

// 工单地图
export const centerOrderMap = data => {
  return request({
    url: '/bigScreen/carRoadData',
    method: 'get',
    params: data
  })
}


// 超速数据
export const centerSuperList = data => {
  return request({
    url: '/speedRecords/pageList',
    method: 'post',
    data
  })
}

// 超速数据
export const centerSuperCheck = data => {
  return request({
    url: '/speedRecords/check',
    method: 'post',
    data
  })
}

// 超速数据
export const centerSuperlMap = params => {
  return request({
    url: '/speedRecords/detail',
    method: 'get',
    params
  })
}

// 超速数据 导出
export const centerSuperExport = data => {
  return request({
    url: '/speedRecords/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 异常事件
export const centerUnusualList = data => {
  return request({
    url: '/carAbnormalInfo/pageList',
    method: 'post',
    data
  })
}

// 异常事件 导出
export const centerUnusualExport = data => {
  return request({
    url: '/carAbnormalInfo/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 异常事件 核查
export const centerUnusualCheck = data => {
  return request({
    url: '/carAbnormalInfo/check',
    method: 'post',
    data
  })
}

// 异常事件 核查
export const centerUnusualMap = params => {
  return request({
    url: '/carAbnormalInfo/detail',
    method: 'get',
    params
  })
}



// 基础信息-处置证-车辆管理API
export const carLicenseList = data => {
  return request({
    url: '/carLicense/pageList',
    method: 'post',
    data
  })
}

// 基础信息-处置证-车辆管理API 导入文件
export const carLicenseUpload = data => {
  return request({
    url: '/carLicense/upload',
    method: 'post',
    data
  })
}

// 基础信息-处置证-车辆管理API 模板下载
export const carLicenseDownloadTemplate = data => {
  return request({
    url: '/carLicense/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

// 基础信息-处置证-车辆管理API 文件下载
export const carLicenseDownload = data => {
  return request({
    url: '/carLicense/download',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
